import { StoreService } from './store.service';
import {
  OnDestroy,
  OnInit,
  OnChanges,
  EventEmitter,
  ElementRef,
  Input,
  Output,
  SimpleChanges,
  Directive
} from '@angular/core';
import { Chart } from 'chart.js';

import { NgChartjsService } from './ng-chartjs.service';
import { getColors } from './colors';
/* tslint:disable-next-line */
@Directive({ selector: 'canvas[ngChartjs]', exportAs: 'ng-chart-js' })
export class NgChartjsDirective implements OnDestroy, OnChanges, OnInit {

  // å¾è¡¨çç¹éï¼å®åºè¯¥æ¯æ°ç»<number []>ä»ç¨äºçº¿ï¼æ¡åé·è¾¾ï¼å¦åæ°å­[];
  @Input() data: number[] | any[];
  // ç¸å½äºchart.jså data: {datasets: [{...}]}
  @Input() datasets: any[];
  // xè½´æ ç­¾ãè¿å¯¹å¾è¡¨æ¥è¯´æ¯å¿è¦çï¼çº¿ï¼æ¡åé·è¾¾ãå¹¶ä¸åªæ¯å¾è¡¨çæ ç­¾ï¼æ¬åï¼ï¼polarAreaï¼pieådoughnut
  @Input() labels: any[] = [];
  // ç¸å½äºchart.jsçoption
  @Input() options: any = {};
  // åèæä»¶å±æ§
  @Input() inlinePlugins: any[];
  // chartType line, bar, radar, pie, polarArea, doughnut
  @Input() chartType: string;
  // æ°æ®é¢è²ï¼å¦ææ²¡ææå®ï¼å°ä½¿ç¨é»è®¤å|æéæºé¢è²
  @Input() colors: any[];
  // æ¯å¦æ¾ç¤ºå¾ä¾
  @Input() legend: boolean;

  @Input() adding: { labels: any[], data: any[][] };
  @Input() removing: { orientation: string };  // orientation is 'oldest' or 'latest
  @Input() resetOption: any;

  // é¼ æ ç¹å»å¾è¡¨ææçåºå
  @Output() chartClick: EventEmitter<any> = new EventEmitter();
  // é¼ æ æ¬æµ®å¨æ ç­¾æèæ´»è·çç¹ä¸é¢æ¶
  @Output() chartHover: EventEmitter<any> = new EventEmitter();

  public ctx: any;
  public chart: any;
  private cvs: any;
  private initFlag = false;
  private hasChanges = false;

  private element: ElementRef;

  public constructor(element: ElementRef,
    private ngChartjsService: NgChartjsService,
    private storeService: StoreService) {
    this.element = element;   // è·åæä»¤æå¨canvasåç´ 
  }

  ngOnInit() {
    this.ctx = this.element.nativeElement.getContext('2d'); // è·ååç´ çctx
    this.cvs = this.element.nativeElement;  // è·åè¿ä¸ªåç´ 
    this.initFlag = true; // æ¯å¦åå§åäºçæ å¿

    if (this.data || this.datasets) { // å¤æ­dataådatasetsæä¸ä¸ªææ°æ®å°±å·æ°
      this.refresh();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // TODO: æä»¶ååå·æ°ï¼å¼æ¾å·æ°æé®
    if (this.initFlag) {
      // Check if the changes are in the data or datasets
      if (changes.hasOwnProperty('data') || changes.hasOwnProperty('datasets')) {
        if (changes.data) {
          this.updateChartData(changes.data.currentValue);
        } else {
          this.updateChartData(changes.datasets.currentValue);
        }
        this.hasChanges = true;
      }

      if (changes.hasOwnProperty('labels')) {
        this.chart.data.labels = changes.labels.currentValue;
        this.hasChanges = true;
      }

      if (changes.hasOwnProperty('legend')) {
        if (changes.legend.currentValue !== changes.legend.previousValue) {
          this.chart.options.legend.display = changes.legend.currentValue;
          this.hasChanges = true;
        }
      }

      if (changes.hasOwnProperty('adding')) {
        this.addData(changes.adding.currentValue.labels, changes.adding.currentValue.data);
        this.hasChanges = true;
      }

      if (changes.hasOwnProperty('removing')) {
        if (changes.removing.currentValue.orientation === 'oldest' || changes.removing.currentValue.orientation === 'latest') {
          this.removeData(changes.removing.currentValue.orientation);
          this.hasChanges = true;
        }
      }

      if (changes.hasOwnProperty('resetOption')) {
        Object.assign(this.chart.options, changes.resetOption.currentValue);
        this.hasChanges = true;
      }
      // else {
      //   // otherwise rebuild the chart
      //   this.refresh();
      // }
      if (this.hasChanges) {
        this.chart.update();
        this.hasChanges = false;
      }
    }
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;

      if (this.element.nativeElement.hasAttribute('id')) {
        this.storeService.removeChart(this.element.nativeElement.id);  // delete chart instance.
      }
    }
  }

  private refresh(): any {
    this.ngOnDestroy();
    this.chart = this.getChartBuilder(this.ctx/*, data, this.options*/);
    if (this.element.nativeElement.hasAttribute('id')) {
      this.storeService.addChart(this.element.nativeElement.id, this.chart);
    }
  }

  private addData(labels: any[], data: any[][]) {
    if (labels.length === 0 || data.length === 0) {
      return;
    }
    // update labels
    labels.forEach((label) => { this.chart.data.labels.push(label); });

    this.chart.data.datasets.forEach((dataset, index) => {
      if (data[index]) {
        for (let i = 0; i < data[index].length; i++) {
          dataset.data.push(data[index][i]);
        }
      } else {
        console.log('The added data does not match the original data');
        return;
      }
    });
  }
  // direction is 'ildest' or 'latest'
  private removeData(direction: string) {
    if (direction === 'latest') {
      this.chart.data.labels.pop();
      this.chart.data.datasets.forEach((dataset) => {
        dataset.data.pop();
      });
      return;
    }
    if (direction === 'oldest') {
      return;
    }
  }
  private updateChartData(newDataValues: number[] | any[]): void {
    if (Array.isArray(newDataValues[0].data)) {
      this.chart.data.datasets.forEach((dataset: any, i: number) => {
        dataset.data = newDataValues[i].data;

        if (newDataValues[i].label) {
          dataset.label = newDataValues[i].label;
        }
      });
    } else {
      this.chart.data.datasets[0].data = newDataValues;
    }
  }

  getChartBuilder(ctx: any/*, data:Array<any>, options:any*/): any {
    const datasets: any = this.getDatasets();

    const options: any = Object.assign({}, this.options); // æ·±å¤å¶options
    if (this.legend === false) {  // è®¾ç½®optionsçlegend TODO: åç»­è¿ä¸ªå±æ§å»é¤ï¼ç´æ¥å¨optionsåè®¾ç½®
      options.legend = { display: false };
    }
    // hock for onHover and onClick events
    options.hover = options.hover || {};
    if (!options.hover.onHover) {
      options.hover.onHover = (active: any[]) => {
        if (active && !active.length) {
          return;
        }
        this.chartHover.emit({ active });
      };
    }

    if (!options.onClick) {
      options.onClick = (event: any, active: any[]) => {
        this.chartClick.emit({ event, active });
      };
    }

    const opts = {
      type: this.chartType,
      data: {
        labels: this.labels,
        datasets: datasets   // TODO: åç»­æ´æ¹è¿ä¸ªå±æ§åå­ï¼å¦åè­¦å
      },
      options: options,   // TODO: åç»­æ´æ¹è¿ä¸ªå±æ§åå­ï¼å¦åè­¦å
      plugins: this.inlinePlugins
    };

    return new Chart(ctx, opts);
  }

  // è·å chart.jsçdatasetsæ°æ®
  private getDatasets(): any {
    let datasets: any = void 0;
    // in case if datasets is not provided, but data is present
    if (!this.datasets || !this.datasets.length && (this.data && this.data.length)) {
      if (Array.isArray(this.data[0])) {
        datasets = (this.data as number[][]).map((data: number[], index: number) => {
          return { data, label: this.labels[index] || `Label ${index}` };
        });
      } else {
        datasets = [{ data: this.data, label: `Label 0` }];
      }
    }

    if (this.datasets && this.datasets.length ||
      (datasets && datasets.length)) {
      datasets = (this.datasets || datasets)
        .map((elm: number, index: number) => {
          const newElm: any = Object.assign({}, elm);
          if (this.colors && this.colors.length) {
            Object.assign(newElm, this.colors[index]);
          } else {
            Object.assign(newElm, getColors(this.chartType, index, newElm.data.length));
          }
          return newElm;
        });
    }

    if (!datasets) {
      throw new Error(`ng-charts configuration error,
      data or datasets field are required to render char ${this.chartType}`);
    }

    return datasets;
  }
}
